<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <searchHead compType="store-unpack-detail" @search="search" @reset="search" />
        <tableComp
          v-loading="loading"
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="goodInfo" label="类目/品牌/型号">
            <template v-slot="scope">
              <span>
                {{ scope.row.categoryName || '-' }} /
                {{ scope.row.brandName || '-' }} /
                {{ scope.row.modelName || '-' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column slot="rate" label="不良率">
            <template v-slot="scope">
             {{scope.row.rate+'%'}}
            </template>
          </el-table-column>
          <el-table-column slot="packCount" label="开箱不良(台)">
            <template v-slot="scope">
              <div
                v-if="scope.row.packCount"
                style="line-height:42px;cursor:pointer;color:#385BFF"
                @click="countDetail(scope.row)"
              >
                {{ scope.row.packCount }}
              </div>
              <div v-else> - </div>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
     <countDetail :isShow="detailShow" @close="close" :orderCode="orderCode" />
  </div>
</template>

<script>
import searchHead from '@/views/data-center/components/searchHead'
import countDetail from '@/views/data-center/components/countDetail'
import { deliverDetailList } from '@/api'
export default {
  components: { searchHead, countDetail },
  data () {
    return {
      loading: false,
      detailShow: false,
      orderCode: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      orderType: 1,
      timeType: 3,
      commodityName: '',
      startTime: '',
      endTime: '',
      theadName: [
        { slot: 'rate' },
        { prop: 'customerName', label: '客户' },
        { prop: 'orderCode', label: '订单号' },
        { prop: 'commodityName', label: '商品名称' },
        { slot: 'goodInfo' },
        { prop: 'deliveryTime', label: '发货时间' },
        { prop: 'deliveryNum', label: '发货数量(台)' },
        { slot: 'packCount' }

      ]
    }
  },
  mounted () {
    const { timeType, startTime, endTime } = this.$route.query
    this.timeType = +timeType
    this.startTime = startTime
    this.endTime = endTime
    this.getList()
  },
  computed: {},
  methods: {
    getList () {
      const { type, storeId } = this.$route.query
      const params = {
        type,
        storeId,
        curPage: this.curPage,
        pageSize: this.pageSize,
        commodityName: this.commodityName,
        orderType: this.orderType,
        timeType: this.timeType,
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.loading = true
      deliverDetailList(params).then(res => {
        this.loading = false
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
      })
    },
    // 开箱不良明细
    countDetail ({ orderCode }) {
      this.orderCode = orderCode
      this.detailShow = true
    },
    close () {
      this.detailShow = false
    },
    search (val) {
      this.curPage = 1
      const { commodityName, orderType, timeType, startTime, endTime } = val
      this.commodityName = commodityName
      this.orderType = orderType
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
